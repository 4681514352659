export default {
    "title": "🇪🇸 Español",

    "首页": "Inicio",
    "关于": "Acerca de",
    "会员": "Membresía",
    "推荐计划": "Programa de Referidos",
    "个人": "Perfil",
    "推荐": "Referencia",
    "预订": "Reservar Ahora",
    "事件": "Evento",
    "登出": "Cerrar Sesión",
    "登录": "Entra",
    "注册": "Crear cuenta",
    "我们的应用程序现在可用": "Nuestra app ya está disponible",
    "为了获得更好的体验，我们刚刚推出了移动应用程序，以实现无缝、更快的预订。": "Para una mejor experiencia, acabamos de lanzar nuestras aplicaciones móviles para reservas más rápidas y sin problemas.",
    "iOS版下载（即将推出）": "Descarga para iOS (Próximamente)",
    "Android版下载（即将推出）": "Descarga para Android (Próximamente)",
    "为什么选择我们": "Por qué Elegirnos",
    "我们卓越的服务。": "Nuestros servicios excepcionales.",
    "收入最高的酒店": "Atracciones con Mayores Ingresos",
    "我们的合作酒店收入最高。": "Nuestras atracciones colaborativas con mayores ingresos.",
    "回报": "Retornos",
    "最具特色的酒店": "Atracciones Más Destacadas",
    "我们平台上最有特色的酒店。": "Hotel más destacado en nuestra plataforma.",
    "热门国家和目的地": "Países y Destinos Populares",
    "从我们的合作伙伴和朋友那里了解一些来自城市各地的最佳提示。": "Explora algunos de los mejores consejos de la ciudad de nuestros socios y amigos.",
    "酒店": "Atracciones",
    "预订酒店并开始赚取": "Explora el mundo con nosotros!",
    "让我们开始与TitanTraveler一起探索世界": "Prepárate para explorar el mundo de las atracciones con nosotros!",
    "客户评价": "Testimonios",
    "查看我们用户的真实评价。": "Consulta nuestros testimonios auténticos de nuestros usuarios.",
    "银行卡列表": "Lista de Tarjetas Bancarias",
    "银行列表": "Lista de Bancos",
    "持卡人姓名": "Nombre del Titular de la Tarjeta",
    "银行卡号": "Número de Cuenta Bancaria（ IBAN ）",
    "支付密码": "Contraseña de Pago",
    "提交": "Enviar",
    "Pix列表": "Lista de Pix",
    "Pix类型": "Tipo de Pix",
    "Pix账号": "Cuenta de Pix",
    "账号列表": "Lista de Cuentas",
    "网络": "Red",
    "地址": "Dirección",
    "备注": "Nota",
    "充值金额": "Monto de Recarga",
    "支付类型": "Tipo de Pago",
    "支付银行": "Banco de Pago",
    "USDT地址": "Dirección USDT",
    "二维码": "Código QR",
    "充值渠道": "Canal de Recarga",
    "充值凭证": "Comprobante de Recarga",
    "立即登录": "Iniciar Sesión Ahora",
    "用户名": "Nombre de Usuario",
    "密码": "Contraseña",
    "邀请码": "Código de Invitación",
    "确认密码": "Confirmar Contraseña",
    "手机": "Móvil",
    "注册2": "Registrarse",
    "金额": "Cantidad",
    "新增": "Nuevo",
    "我们的精彩": "Nuestro Asombroso",
    "故事": "Historia",
    "奖项": "Premios",
    "获得": "Obtenido",
    "钱包余额": "Saldo de la Billetera",
    "今日奖励": "Recompensas de Hoy",
    "总行程": "Viaje Total",
    "访问行程": "Viajes Visitados",
    "酒店历史": "Detalles del pedido",
    "单价": "Precio",
    "数量": "Número",
    "总额": "Total",
    "奖励": "Recompensas",
    "上一页": "Anterior",
    "下一页": "Siguiente",
    "每套预订量": "Reservas por Conjunto",
    "预订奖励": "Recompensa por Reserva",
    "每日预订": "Reserva Diaria",
    "手机号码": "Número de Teléfono",
    "修改密码": "Cambiar Contraseña",
    "登录密码": "Contraseña de Inicio de Sesión",
    "当前密码": "Contraseña Actual",
    "新密码": "Nueva Contraseña",
    "确认新密码": "Confirmar Nueva Contraseña",
    "保存修改": "Guardar Cambios",
    "充值": "Recargar",
    "我们的": "Nuestro",
    "复制": "Copiar",
    "分享链接": "Compartir Enlace",
    "搜索酒店": "Reservar Viaje",
    "实际等级": "Nivel Actual",
    "升级": "Actualizar",
    "已完成预订": "Reservas Completadas",
    "登录时间": "Hora de Inicio de Sesión",
    "信誉分": "Credibilidad",
    "控制菜单": "Menú del Tablero",
    "提现": "Retirar",
    "提现账号": "Cuenta de Retiro",
    "你确定要删除此账号吗": "¿Estás seguro de que deseas eliminar esta cuenta?",
    "你确定要删除此地址吗": "¿Estás seguro de que deseas eliminar esta dirección?",
    "提示": "Consejos",
    "是": "Sí",
    "否": "No",
    "全部": "Todo",
    "待处理": "Pendiente",
    "冻结中": "Congelando",
    "已完成": "Completado",
    "订阅": "Suscribirse",
    "希望在我们推出新模板或更新时收到通知。 只需注册，我们就会通过电子邮件向您发送通知。": "¿Quieres ser notificado cuando lancemos un nuevo template o una actualización? Simplemente regístrate y te enviaremos una notificación por correo electrónico.",
    "我们的伙伴": "Nuestros socios",
    "关于我们": "SOBRE NOSOTROS",
    "实时聊天支持": "Soporte de Chat en Vivo",
    "在线聊天": "Chat en Vivo",
    "创建时间": "Hora de Creación",
    "你确定要登出当前账号吗？": "¿Deseas cerrar sesión?",
    "请输入你的邮箱": "Ingresa tu Correo Electrónico",
    "好评": "Muy Bueno",
    "条评论": "Reseñas",
    "平均每晚": "Promedio/Noche",
    "旅行旅程": "Viajes",
    "正在寻找酒店": "Buscando un viaje",
    "开始预订": "Iniciar Reserva",
    "旅程价格": "Precio del Viaje",
    "佣金": "Comisiones",
    "底部-about": "Somos una agencia de marketing digital con sede en California, Estados Unidos, que permite a las empresas tener éxito en el mundo digital a través de estrategias de marketing innovadoras y basadas en datos, servicio personalizado y una mentalidad de crecimiento que mantiene el pulso de las tendencias de la industria. Ya sea que necesites una agencia SEO, diseñador de sitios web, gestión de pago por clic, estrategia de marca, desarrollo de contenido u otros servicios de marketing digital, Tours4fun tiene la experiencia y las habilidades para ayudarte a lograr tus objetivos en un mundo orientado a los resultados.",
    "立即预订": "Reservar Ahora",
    "搜索": "Buscar",
    "快乐的客户": "Cliente Feliz",
    "快乐的酒店老板": "Propietario de Hotel Feliz",
    "评论1": "Arquitectura en edificios.",
    "评论2": "Este es un gran lugar para pasear con calles estrechas y una hermosa arquitectura en los edificios.",
    "评论3": "Plaza hermosa entre la playa y la Avenida Central.",
    "评论4": "Edificios muy bonitos con una historia interesante.",
    "评论5": "Muchos restaurantes para elegir y un ambiente muy relajado.",
    "评论6": "Tierra hermosa con encantadores huertos de naranjas y animales.",
    "评论7": "Gran lugar para una escapada de fin de semana.",
    "评论8": "Lugar interesante ......",
    "评论9": "Un gran lugar para sentarse y ver pasar el mundo.",
    "评论10": "Club de playa de alto nivel que es un hermoso tributo a aquellos que murieron en la guerra.",
    "评论11": "Hay tanto que ver y es un gran lugar para vacacionar y relajarse.",
    "评论12": "Primera vez aquí y fue muy agradable, volveré la próxima vez.",
    "评论13": "Fue una experiencia diferente y se sintió genial, ¡los lugareños fueron muy amables y corteses!",
    "推荐计划1": "Los usuarios de la plataforma pueden invitar a otros a convertirse en agentes de la plataforma a través de códigos de referencia y convertirse en tu línea descendente.",
    "推荐计划2": "Como línea ascendente, puedes extraer un cierto porcentaje de las ganancias de tu línea descendente, y las ganancias obtenidas por la línea ascendente se devolverán directamente a la cuenta de la plataforma de la línea ascendente o al informe del equipo.",
    "推荐计划3": "Nota: Todos los agentes/líneas descendentes de la plataforma recibirán un cierto porcentaje de las ganancias y recompensas de la plataforma en consecuencia; desarrollar un equipo no afecta las ganancias y recompensas de ningún agente o líneas descendentes.",
    "评分": "Calificación",
    "评论": "Comentario",
    "酒店很棒": "El hotel es genial",
    "终于体验了，酒店非常好": "Finalmente lo experimenté, el hotel es muy bueno",
    "我对酒店非常满意并推荐预订": "Estoy muy satisfecho con el hotel y recomiendo reservar",
    "我很高兴很满意，价格实惠": "Estoy muy feliz y satisfecho, el precio es asequible",
    "服务非常好，我非常喜欢": "El servicio es muy bueno, me gusta mucho",
    "体验很不错，朋友已经第二次预订了": "La experiencia fue muy buena, mi amigo ya ha reservado por segunda vez",
    "酒店环境优美": "Entorno del hotel bonito",
    "酒店卫生干净": "El hotel es higiénico y limpio",
    "工作人员态度非常好": "La actitud del personal es muy buena",
    "酒店服务很棒": "El servicio del hotel es excelente",
    "你还没有设置支付密码，请前往设置": "No has establecido una contraseña de pago, por favor ve a configuraciones",
    "订单": "Órdenes",
    "复制成功": "Copia exitosa",
    "团队与条件": "Términos y Condiciones",
    "站内信": "Mensajes del Sitio",
    "请联系客服升级会员": "Por favor, contacta al servicio al cliente para actualizar la membresía",
    "我已阅读并同意": "He leído y acepto el",
    "注册协议": "Acuerdo de Registro",
    "忘记密码": "Olvidé la contraseña",
    "账户明细": "Detalles de la Cuenta",
    "时间": "Hora",
    "类型": "Tipo",
    "代理支持": "Soporte para Agentes",
    "冻结金额": "Monto Congelado",

    "点击下面的金蛋抽取奖励": "Haga clic en el huevo de oro a continuación para obtener recompensas.",
    "恭喜您中奖了！": "¡Felicitaciones por ganar!",
    "很遗憾，您没有中奖": "Lo siento, no ganaste",
    "公告": "anuncio",
}